@import url('https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

@font-face {
    font-family: 'Kalmansk';
    src: local('Kalmansk'), url('../src/assets/fonts/Kalmansk.otf') format('otf');
}

body {
    font-family: "K2D", sans-serif;
    margin: 0;
    padding: 0;
}



html, body {
    cursor: none !important;
    height: 100%;
    width: 100%;
    font-size: 14px;
    line-height: 1.21em;
    box-sizing: border-box;
    min-height: 100%; /* requires for 'mix-blend-mode' */
    color: #000;
    background-color: #fff;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    display: none;
}

*, *:before, *:after {
    box-sizing: inherit;
    line-height: 1.21em;
}

@media (max-width: 768px) {
    html, body {
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    html, body {
        font-size: 12px;
    }
}

@media (max-width: 360px) {
    html, body {
        font-size: 12px;
    }
}


::placeholder { color:#BDBDBD }
/* webkit */
::-webkit-input-placeholder { color:#BDBDBD }
/* Firefox 19+ */
::-moz-placeholder { color:#BDBDBD }
/* IE 10+ */
:-ms-input-placeholder { color:#BDBDBD }
/* Firefox 18- */
:-moz-placeholder { color:#BDBDBD }
